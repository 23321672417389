body {
  background-color: rgba(0, 5, 10, 0.9);
  color: aliceblue;
}
.app-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 1em;
}
.container {
  width: 100%;
  margin: 0 auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
.border-temp {
  border: 2px solid green;
}
