.crypto-wrapper {
  border: 1px solid rgba(220, 220, 220, 0.5);
  background-color: rgba(0, 5, 10, 1);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.inner-price-wrapper {
  display: inherit;
  flex-direction: column;
  align-items: center;
  padding: 0 12px;
}

.trade-wrapper {
  display: inherit;
  flex-direction: column;
  align-items: center;
}

.ask-wrapper {
  display: inherit;
  flex-direction: column;
  align-items: center;
  padding: 0 4px;
}

.bid-wrapper {
  display: inherit;
  flex-direction: column;
  align-items: center;
  padding: 0 4px;
}

.size-wrapper {
  display: inherit;
  flex-direction: column;
  align-items: center;
}

.time-wrapper {
  display: inherit;
  flex-direction: column;
  align-items: center;
}
