.indices-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.indices-content-wrapper {
  display: inherit;
  flex-direction: column;
  align-items: center;
}
